<template>
  <div class="filter-dates">
    <button
      ref="buttonEl"
      type="button"
      class="search-toggle"
      @click="toggle"
    >
      {{ t('dates.title') }}
      <SearchDatesText :dates="dates" />
    </button>

    <ClientOnly>
      <div
        v-if="isLargeBreakpoint && showForm"
        ref="floatingEl"
        class="floating-box"
        :style="floatingStyles"
      >
        <LazySearchDatesForm
          :dates="datesModel"
          @update:dates="updateDates($event, !showFormButtons)"
        >
          <template
            v-if="showFormButtons"
            #default="{ dates: newDates }"
          >
            <BannersSeasonal v-if="showThanksgivingBanner" />
            <LazySearchFormButtons
              @apply="apply({ dates: newDates })"
              @clear="clear"
            />
          </template>
          <template v-if="!showFormButtons">
            <BannersSeasonal v-if="showThanksgivingBanner" />
          </template>
        </LazySearchDatesForm>
      </div>

      <OverlayDrawer
        v-if="!isLargeBreakpoint"
        :visible="showForm"
        :prevent-control-scroll="preventOverlayControlScroll"
        :title="t('dates.title')"
        @close="close()"
      >
        <LazySearchDatesForm
          :dates="datesModel"
          @update:dates="updateDates($event, false)"
        >
          <template #default="{ dates: newDates }">
            <BannersSeasonal v-if="showThanksgivingBanner" />
            <LazySearchFormButtons
              @apply="apply({ dates: newDates })"
              @clear="clear"
            />
          </template>
        </LazySearchDatesForm>
      </OverlayDrawer>
    </ClientOnly>
  </div>
</template>

<script setup lang="ts">
import { autoUpdate, offset, shift, useFloating } from '@floating-ui/vue'
import { EXPERIMENT_KEYS } from '~/constants/experiment-keys'
import { shouldShowThanksgivingBanner } from '~/lib/search'

import type { SearchDates } from '~/types/search'

const props = defineProps<{
  dates: SearchDates['dates']
  openCalendar?: boolean
  showFormButtons?: boolean
  preventOverlayControlScroll?: boolean
}>()

const emit = defineEmits<{
  'apply': [dates: { dates: SearchDates['dates'] }]
  'clear': []
  'close': []
  'update:dates': [dates: SearchDates['dates']]
}>()

const { isLargeBreakpoint } = useBreakpoint()
const { t } = useI18n()
const { $experiment } = useNuxtApp()
const { geolocation } = useGeolocation()

const { showForm, forceOpen, toggle, close } = useShowForm()
const { buttonEl, floatingEl, floatingStyles } = useFloatingCalendar()

const showThanksgivingBanner = computed(() => {
  const experiment = $experiment.getVariant(EXPERIMENT_KEYS.rvz20599ThanksgivingBanner)
  const userCountry = geolocation.value?.countryCode || ''
  return shouldShowThanksgivingBanner(String(experiment), userCountry)
})

const datesModel = ref(props.dates)
watch(
  () => props.dates,
  (newVal) => {
    datesModel.value = newVal
  },
  { deep: true },
)

function updateDates(dates: SearchDates['dates'], emitUpdate = false) {
  datesModel.value = dates

  if (!emitUpdate) {
    return
  }

  emit('update:dates', dates)

  if (dates.start && dates.end) {
    close()
  }
}

function apply({ dates }: { dates: Record<string, unknown> }) {
  emit('apply', { dates })
  close()
}

function clear() {
  emit('clear')
  close()
}

function useShowForm() {
  const showForm = ref(false)
  const forceOpen = ref(false)

  watch(
    () => props.openCalendar,
    (newVal) => {
      if (newVal) {
        forceOpen.value = true
        toggle()
        setTimeout(() => {
          forceOpen.value = false
        }, 250)
      }
    },
  )

  function toggle() {
    showForm.value = !showForm.value

    if (!showForm.value) {
      emit('close')
    }
  }

  function close() {
    showForm.value = false
    emit('close')
  }

  return {
    showForm,
    forceOpen,
    toggle,
    close,
  }
}

function useFloatingCalendar() {
  const buttonEl = ref<HTMLButtonElement>()
  const floatingEl = ref<HTMLDivElement>()
  const { floatingStyles, middlewareData } = useFloating(buttonEl, floatingEl, {
    placement: 'bottom',
    middleware: [offset(24), shift({ padding: 16 })],
    whileElementsMounted: autoUpdate,
  })

  onClickOutside(floatingEl, () => {
    if (!isLargeBreakpoint.value || !showForm.value || forceOpen.value) {
      return
    }
    showForm.value = false
  }, { ignore: [buttonEl] })

  return {
    buttonEl,
    floatingEl,
    floatingStyles,
    middlewareData,
  }
}
</script>

<style lang="scss" scoped>
.floating-box {
  max-width: max-content;
}

:deep(.calendars-container) {
  @include media-min-size(medium) {
    max-width: none;
  }
}
</style>

<i18n src="~/locales/common/search/form/dates.json" lang="json" />
